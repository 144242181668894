import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import { BsCheckCircleFill } from "react-icons/bs";
import Button from "../elements/Button";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Pricing = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Pricing Plans",
    paragraph: "Get started for free. Grow when you're ready.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <h5
                  style={{
                    background: "#353E4A",
                    width: "intrinsic",
                    padding: 4,
                    paddingLeft: 14,
                    paddingRight: 14,
                    borderRadius: 24,
                  }}
                >
                  Basic
                </h5>
                <h1 style={{ marginTop: 0 }}>
                  Free
                  <span style={{ fontSize: 16 }} />
                </h1>
                <div>
                  <ul
                    style={{
                      listStyleType: "none",
                      marginLeft: -20,
                      marginBottom: 36,
                    }}
                  >
                    <li style={{ display: "flex" }}>
                      <BsCheckCircleFill
                        style={{ color: "#52AD88", marginRight: 8 }}
                      />
                      Schedule up to 5 tweets
                    </li>
                    <li style={{ display: "flex" }}>
                      <BsCheckCircleFill
                        style={{ color: "#52AD88", marginRight: 8 }}
                      />
                      Tweet once per day
                    </li>
                    <li style={{ display: "flex" }}>
                      <BsCheckCircleFill
                        style={{ color: "#52AD88", marginRight: 8 }}
                      />
                      Quote other accounts
                    </li>
                  </ul>
                </div>
                <Button
                  tag="a"
                  color="primary"
                  wideMobile
                  href="https://app.timeless.so/"
                >
                  Sign Up
                </Button>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <h5
                  style={{
                    background: "#6163F6",
                    width: "intrinsic",
                    padding: 4,
                    paddingLeft: 14,
                    paddingRight: 14,
                    borderRadius: 24,
                  }}
                >
                  Pro
                </h5>
                <h1 style={{ marginTop: 0 }}>
                  $10 <span style={{ fontSize: 16 }}>/ Month</span>
                </h1>
                <div>
                  <ul
                    style={{
                      listStyleType: "none",
                      marginLeft: -20,
                      marginBottom: 36,
                    }}
                  >
                    <li style={{ display: "flex" }}>
                      <BsCheckCircleFill
                        style={{ color: "#52AD88", marginRight: 8 }}
                      />
                      Schedule unlimited tweets
                    </li>
                    <li style={{ display: "flex" }}>
                      <BsCheckCircleFill
                        style={{ color: "#52AD88", marginRight: 8 }}
                      />
                      Tweet 5 times per day
                    </li>
                    <li style={{ display: "flex" }}>
                      <BsCheckCircleFill
                        style={{ color: "#52AD88", marginRight: 8 }}
                      />
                      Quote other accounts
                    </li>
                  </ul>
                </div>
                <Button
                  tag="a"
                  color="primary"
                  wideMobile
                  href="https://app.timeless.so/"
                >
                  Sign Up
                </Button>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <h5
                  style={{
                    background: "#6163F6",
                    width: "intrinsic",
                    padding: 4,
                    paddingLeft: 14,
                    paddingRight: 14,
                    borderRadius: 24,
                  }}
                >
                  Annual
                </h5>
                <h1 style={{ marginTop: 0 }}>
                  $8 <span style={{ fontSize: 16 }}>/ Month</span>
                </h1>
                <div>
                  <ul
                    style={{
                      listStyleType: "none",
                      marginLeft: -20,
                      marginBottom: 36,
                    }}
                  >
                    <li style={{ display: "flex" }}>
                      <BsCheckCircleFill
                        style={{ color: "#52AD88", marginRight: 8 }}
                      />
                      Schedule unlimited tweets
                    </li>
                    <li style={{ display: "flex" }}>
                      <BsCheckCircleFill
                        style={{ color: "#52AD88", marginRight: 8 }}
                      />
                      Tweet 5 times per day
                    </li>
                    <li style={{ display: "flex" }}>
                      <BsCheckCircleFill
                        style={{ color: "#52AD88", marginRight: 8 }}
                      />
                      Quote other accounts
                    </li>
                  </ul>
                </div>
                <Button
                  tag="a"
                  color="primary"
                  wideMobile
                  href="https://app.timeless.so/"
                >
                  Sign Up
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;
