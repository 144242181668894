import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import FeaturesTiles from "../components/sections/FeaturesTiles";
//import FeaturesSplit from "../components/sections/FeaturesSplit";
//import Testimonial from "../components/sections/Testimonial";
import Pricing from "../components/sections/Pricing";
//import Cta from '../components/sections/Cta';

const Home = () => {
  return (
    <>
      <Hero />
      {/* <FeaturesSplit invertMobile topDivider imageFill /> */}
      <FeaturesTiles />
      <Pricing />
      {/* <Testimonial topDivider /> */}
    </>
  );
};

export default Home;
